

document.addEventListener('DOMContentLoaded', () => {
  const header = document.getElementById('header'); // Header fijo
  const hamburger = document.getElementById('hamburger'); // Botón hamburguesa
  const menu = document.getElementById('menu'); // Menú desplegable
  const scrollThreshold = window.innerHeight; // Umbral para el fondo del header

  // Cambiar el fondo del header al hacer scroll
  window.addEventListener('scroll', () => {
    // Si el menú está abierto, no modificar el header
    if (menu.classList.contains('open')) return;

    // Cambiar el fondo del header según el scroll
    if (window.scrollY > scrollThreshold) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
    }
  });

  // Mostrar/ocultar el menú hamburguesa al hacer clic
  hamburger.addEventListener('click', () => {
    const isOpen = menu.classList.toggle('open'); // Alternar la clase del menú
    document.body.classList.toggle('no-scroll', isOpen); // Activar/desactivar scroll del fondo

    // Cambiar el icono del botón hamburguesa
    hamburger.textContent = isOpen ? '✖' : '☰';
  });
});
